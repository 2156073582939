import { isProductionEnv } from '@netpurpose/utils'

export const sentryConfig = {
  enabled: process.env.NEXT_PUBLIC_MONITORING_ENABLED
    ? process.env.NEXT_PUBLIC_MONITORING_ENABLED === 'true'
    : false,
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://c7a585c9abbd4b8db3b26d418fcac35f@o581227.ingest.sentry.io/6777502',
  environment: isProductionEnv() ? 'production' : 'preview',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}
