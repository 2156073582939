// Using process.env instead of #services/config
// These utils can be invoked before initialisation
const deploymentEnv = process.env['NEXT_PUBLIC_DEPLOYMENT_ENV']

export const isProductionEnv = () => deploymentEnv === 'prod'

export const isPreviewEnv = () => !isProductionEnv()

export const isDevEnv = () => deploymentEnv !== 'prod' && deploymentEnv !== 'staging'

export const isRunningServerSide = () => typeof window === 'undefined'
