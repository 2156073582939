import googleTagManager from '@analytics/google-tag-manager'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import { Analytics } from 'analytics'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import React, { createContext, useMemo } from 'react'
import { Client, HydrationProvider } from 'react-hydration-provider'
import { ThemeProvider } from 'styled-components'
import { isDevEnv, isPreviewEnv } from '@netpurpose/utils'
import { UnleashProvider } from '#components/UnleashProvider'
import { AnalyticsProvider } from '#lib/analytics'
import { OpenGraphData } from '#lib/seo'
import { GlobalStyles } from '#themes/light/GlobalStyles'
import { theme } from '#themes/light/theme'
import type { AppProps as NextAppProps } from 'next/app'

if (typeof window !== 'undefined' && isDevEnv()) {
  // eslint-disable-next-line import/no-extraneous-dependencies
  Promise.all([import('react-dom'), import('@axe-core/react')]).then(([ReactDOM, axe]) => {
    axe.default(React, ReactDOM.default, 1000)
  })
}

// Store Strapi Global object in context
export const GlobalContext = createContext({
  siteName: '',
})

type AppProps<PageProps extends { openGraphData?: OpenGraphData[] }> = {
  pageProps: PageProps
  globalProps: {
    attributes: {
      siteName: string
    }
  }
} & Omit<NextAppProps<PageProps>, 'pageProps' | 'globalProps'>

const MyApp = <PageProps extends { openGraphData?: OpenGraphData[] }>({
  Component,
  pageProps,
  globalProps,
}: AppProps<PageProps>) => {
  const containerId = process.env['NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID'] || 'GTM-XXXX'

  const analytics = useMemo(
    () =>
      Analytics({
        app: 'www.netpurpose.com',
        plugins: [
          googleTagManager({
            containerId,
          }),
        ],
      }),
    [containerId],
  )

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/images/icons/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/icons/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        {pageProps.openGraphData ? (
          pageProps.openGraphData.map((og) => (
            /* Key needs to be defined explicitly to avoid warning in console */
            <React.Fragment key={og.key}>
              {og.key === 'og-title' && <title>{og.content}</title>}
              <meta {...og} />
            </React.Fragment>
          ))
        ) : (
          <title>{globalProps.attributes.siteName}</title>
        )}
      </Head>

      <Script
        id="privasee-cb"
        src="https://app.privasee.io/cdn/646cc22bda2352001473b13e/cookie-banner.js"
      />
      <HydrationProvider>
        <Client>
          <VercelAnalytics
            beforeSend={(event) => {
              if (isPreviewEnv()) {
                return null
              }
              return event
            }}
          />
        </Client>

        <GlobalContext.Provider value={globalProps.attributes}>
          <UnleashProvider>
            <AnalyticsProvider analytics={analytics}>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Component {...pageProps} />
              </ThemeProvider>
            </AnalyticsProvider>
          </UnleashProvider>
        </GlobalContext.Provider>
      </HydrationProvider>
    </>
  )
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (ctx: any) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx)

  // Pass the data to our page via props
  return {
    ...appProps,
    globalProps: {
      attributes: {
        siteName: 'Net Purpose',
      },
    },
  }
}

export default MyApp
