const WHITE = '#FFFFFF'
const GREY1 = '#F3F3F3'
const GREY2 = '#E7E7E7'
const GREY3 = '#CCCCCC'
const GREY4 = '#979797'
const GREY5 = '#555555'
const GREY6 = '#252525'
const BLACK = '#1B1B1B'

const NP_GREEN = '#2AFD62'
const PURPLE = '#552CF6'
const RED = '#D30034'
const GREEN = '#019000'
const TEAL = '#285F68'

const colors = {
  bgPrimary: GREY1,
  bgSecondary: WHITE,
  bgDark: BLACK,

  typePrimary: BLACK,
  typeSecondary: GREY5,
  typeTertiary: GREY4,
  typeDarkPrimary: GREY1,
  typeInlineCode: TEAL,

  actionPrimary: NP_GREEN,
  actionSecondary: BLACK,
  actionLink: PURPLE,
  actionDarkLink: NP_GREEN,

  semanticNegative: RED,
  semanticPositive: GREEN,

  architecture1: WHITE,
  architecture2: GREY1,
  architecture3: GREY2,
  architecture4: GREY3,
  architecture5: GREY4,
  architecture6: GREY5,
  architecture7: GREY6,
  architecture8: BLACK,
} as const

export default colors
