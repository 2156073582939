import dynamic from 'next/dynamic'
import { FC } from 'react'
import { isProductionEnv } from '@netpurpose/utils'

const FlagProvider = dynamic(() => import('@unleash/proxy-client-react'), { ssr: false })

const unleashDevConfig = {
  url: 'https://unleash.staging.netpurpose.com/proxy',
  clientKey: '*:development.2fc197311aeeba73472ffa7b14c115236c8e48b1261aab9bd2c3c71a',
  refreshInterval: 30, // seconds
  appName: 'np-unleash',
  environment: 'dev',
} as const

const unleashProdConfig = {
  url: 'https://unleash.prod.netpurpose.com/proxy',
  // It's ok to hardcode this, it only allows reading which toggles are enabled/disabled.
  clientKey: '*:production.6e815d308fe566c9b8f323d2e8dddc317b54d449d1cf4aff76c1b65e',
  refreshInterval: 30, // seconds
  appName: 'np-unleash',
  environment: 'prod',
}

export const UnleashProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <FlagProvider config={isProductionEnv() ? unleashProdConfig : unleashDevConfig}>
    {children}
  </FlagProvider>
)
