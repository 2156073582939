import { AnalyticsInstance } from 'analytics'
import { createContext, useContext } from 'react'
import { Tail } from '@netpurpose/types'

// Only event names in this enum should be used, as they need to be configured
// in Google Tag Manager for them to be triggered.
export enum AnalyticsEventName {
  LinkClick = 'link_click',
  ButtonClick = 'button_click',
  FormSubmit = 'form_submit',
}

type ModifiedTrack = (
  eventName: AnalyticsEventName,
  ...rest: Tail<Parameters<AnalyticsInstance['track']>>
) => ReturnType<AnalyticsInstance['track']>

type ModifiedAnalyticsInstance = Omit<AnalyticsInstance, 'track'> & {
  track: ModifiedTrack
}

const AnalyticsContext = createContext<ModifiedAnalyticsInstance | null>(null)

export const AnalyticsProvider = ({
  children,
  analytics,
}: {
  children: React.ReactNode
  analytics: ModifiedAnalyticsInstance
}) => {
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = () => useContext(AnalyticsContext)
