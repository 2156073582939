import colors from './colors'

export const theme = {
  colors,
  zIndices: {
    navMenu: 1,
    semiCircleFeaturesSectionForeground: 1,
    semiCircleFeaturesSectionBackground: -1,
  },
  spacing: {
    regular: '1.5rem',
    medium: '3.125rem',
    large: '6.75rem',
  },
  breakpoints: {
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1440px',
  },
  sizes: {
    centeredTextMaxWidth: '70rem',
  },
  maxWidth: '1840px',
} as const
